import { h } from 'preact';
import Markdown from 'preact-markdown';
import style from './Home.css';
import index from './index.md';

const Home = () => (
    <div class={style.home}>
      <Markdown markdown={index} />
    </div>
);

export default Home;
