import { h } from 'preact';
import style from './Contact.css';

const Contact = () => (
    <div class={style.contact}>
	<h1>Contact</h1>
	<div style={{width: "140px"}}>
	    OccoSolutions
	    Juha Okkonen
	    Kirstinmäki 15 C 43
	    02760 Espoo
	</div>
	<p><b>GSM: </b> +358 50 569 5914</p>
	<p><b>EMail: </b>Juha.Okkonen at OccoSolutions.fi</p>
    </div>
);

export default Contact;
