import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './Header.css';
import OccoLogo from '../../assets/icons/Occo_logo.svg';

const Header = ({scrollPos}) => (
    <header class={style.header} style={{top: scrollPos}} >
	<img  className={style.logoCont} src={OccoLogo} alt="OccoLogo"/>
	<nav>
	    <Link activeClassName={style.active} href="/">Home</Link>
	    <Link activeClassName={style.active} href="/contact">Contact</Link>
	    </nav>
    </header>
);

export default Header;
	    //<Link activeClassName={style.active} href="/services">Services</Link>
	    //<Link activeClassName={style.active} href="/projects">Projects</Link>
