import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Header from './header/Header';
import Footer from './footer/Footer';

// Code-splitting is automated for routes
import Home from '../routes/home/Home';
import Contact from '../routes/contact/Contact';
//import Profile from '../routes/profile';

export default class App extends Component
{
    state = { hdr: 0, ftr: 0 };

    componentDidMount()
    {
	var hs = this.scrollWidth > this.clientWidth; 
	//var vs = this.scrollHeight > this.clientHeight; 
	var vs = window.visualViewport.width < window.Width;
	
	window.onscroll = this.onScroll;
	console.log( "Scroll: ", hs, vs );
    }

    componentWillUnmount()
    {
	window.removeEventListener( 'resize', this.updateWindowDimensions );
    }

    onScroll = () =>
    {
	let curScrollPos = window.pageYOffset;

	if( this.prevScrollPos > curScrollPos )
	{
	    this.setState( { hdr: 0, ftr: -100 });
	}
	else
	{
	    this.setState( { hdr: -100, ftr: 0 });
	}
	this.prevScrollPos = curScrollPos;
    }

    /** Gets fired when the route changes.
     *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
     *	@param {string} event.url	The newly routed URL
     */
    handleRoute = e => {
	this.currentUrl = e.url;
	this.setState( { hdr: 0, ftr: 0 } ); // Make both Header & Footer visble.
    };

    render() {
	return (
	    <div id="app">
		<Header scrollPos={this.state.hdr}/>
		  <div className="container"><div>Vasen</div>
		    <Router onChange={this.handleRoute}>
			<Home path="/" />
			<Contact path="/contact" />
		    </Router><div>Oikea</div>
		  </div>
		<Footer scrollPos={this.state.ftr} />
	    </div>
	);
    }
//		    <Profile path="/profile/" user="me" />
//		    <Profile path="/profile/:user" />
}
