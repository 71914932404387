import { h } from 'preact';
import { Link } from 'preact-router/match';
import PreactLogo from '../../assets/icons/android-chrome-512x512.png';
import style from './Footer.css';

const Footer = ({scrollPos}) => (
	<footer class={style.footer} style={{bottom: scrollPos}} >
	    <h1>Copyright &copy; 2020 Occo Solutions</h1>
	    <div className={style.logo}>
	      <img className={style.logoCont} src={PreactLogo} alt="PreactLogo"/>
	      <h1>Powered by Preact</h1>
	    </div>
	</footer>
);

export default Footer;
